import { Box, Breadcrumbs, Container, Link, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core'
import { Email, Facebook, Twitter } from "@material-ui/icons"
import BaseLayout from 'components/BaseLayout'
import Footer from 'components/Footer'
import Header from 'components/Header'
import React from 'react'

export default () => {
  return (
    <BaseLayout title={"Budgetocity™ - Support"}>
      <Header />

      <Container maxWidth="sm">
        <Box mb={6}>
          <Breadcrumbs aria-label="navigation">
            <Link color="inherit" href="/" >
              Home
            </Link>
            <Link color="inherit" href="/support" >
              Support
            </Link>
          </Breadcrumbs>
        </Box>

        <Box mb={6}>
          <Typography variant="h5" gutterBottm>Application Support</Typography>
          <Typography variant="body1" gutterBottom>The best way to contact support for Budgetocity™ is through social media:</Typography>
          <List>
            <ListItem component="a" button href="https://facebook.com/budgetocity">
              <ListItemIcon><Facebook /></ListItemIcon>
              <ListItemText primary="Facebook" />
            </ListItem>
            <ListItem component="a" button href="https://twitter.com/budgetocity">
              <ListItemIcon><Twitter /></ListItemIcon>
              <ListItemText primary="Twitter" />
            </ListItem>
            <ListItem component="a" button href="mailto:support@budgetocity.com">
              <ListItemIcon><Email /></ListItemIcon>
              <ListItemText primary="Email (support@budgetocity.com)" />
            </ListItem>
          </List>
        </Box>
      </Container>

      <Footer/>

    </BaseLayout>
  )
}